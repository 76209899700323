(function () {

	// Options and class global vars
	var height = 200;

	var margins = {
		top: 10,
		right: 30,
		bottom: 35,
		left: 60
	};

	/**
	 * @constructor
	 * @extends pluto.Chart
	 */
	pluto.ChartScatter = function (crossData) {

		pluto.Chart.apply(this, arguments);

		var curDimensions = null;

		/**
		 * @lends pluto.ChartScatter.prototype
		 */
		var exports = this;

		// bins creation variables
		exports.max = [0, 0];
		exports.min = [0, 0];

		/**
		 * @override
		 */
		exports._abstract_create = function (parentDiv, selectedProperty, dimensions) {
			curDimensions = dimensions;
			createDimension(selectedProperty);
			createChart(parentDiv, selectedProperty);
			return exports.dcChart;
		};

		/*
		 █████  ███    ██  ██████  ███    ██
		██   ██ ████   ██ ██    ██ ████   ██
		███████ ██ ██  ██ ██    ██ ██ ██  ██
		██   ██ ██  ██ ██ ██    ██ ██  ██ ██
		██   ██ ██   ████  ██████  ██   ████
		*/


		// creates the crossfilter dimension
		function createDimension(selectedProperty) {

			// Find min/max for both sides
			selectedProperty.forEach(function (property, i) {

				// if clamped, do not need to run min max calcs
				if (curDimensions[i] && curDimensions[i].clamps && curDimensions[i].clamps.active) {
					exports.min[i] = curDimensions[i].clamps[0];
					exports.max[i] = curDimensions[i].clamps[1];
					return;
				}

				exports.disposeDimension();

				exports.cdim = crossData.dimension(function (d) {
					return +d[property];
				});

				// avoid invalid values
				exports.cdim.filter(function (d) {
					return d >= 0;
				});

				// min and max values
				var top = exports.cdim.top(1);
				var bottom = exports.cdim.bottom(1);

				// avoid errors
				if (top.length === 0) return;
				if (bottom.length === 0) return;

				// get extrema
				exports.max[i] = top[0][property];
				exports.min[i] = bottom[0][property];

			});

			exports.disposeDimension();

			// create chart dimension
			exports.cdim = crossData.dimension(function (d, i) {
				if (i === 0) exports.legend.clear();
				var d0 = exports.getBinValue(d[selectedProperty[0]], exports.min[0], exports.max[0]);
				var d1 = exports.getBinValue(d[selectedProperty[1]], exports.min[1], exports.max[1]);
				var result = [d0, d1];
				// console.log(result);
				exports.legend.add(result[0], d[selectedProperty[0]], d);
				return result;

			});
		}

		// creates the dc.js chart
		function createChart(parentDiv, selectedProperty) {
			// creates the group
			var grp = exports.cdim.group();

			exports.group = grp;

			// creates the chart
			exports.dcChart = dc.scatterPlot(parentDiv[0]);

			// initialize the chart
			exports.dcChart.height(height)
				.margins({
					left: margins.left,
					right: margins.right,
					top: margins.top,
					bottom: margins.bottom
				})
				// axis scales
				.x(d3.scale.linear()
					.domain([0, exports.bin]))
				.y(d3.scale.linear()
					.domain([0, exports.bin]))
				// grid lines
				.renderVerticalGridLines(true)
				.renderHorizontalGridLines(true)
				//set circle size
				.symbolSize(4)
				// y axis label
				.xAxisLabel(selectedProperty[0])
				.yAxisLabel(selectedProperty[1])
				// padding
				.yAxisPadding(5)
				.xAxisPadding(5)
				// dim and group
				.dimension(exports.cdim)
				.group(grp);

			// x ticks
			exports.dcChart.xAxis()
				.ticks(10)
				.tickFormat(function (v) {
					return tickFormatFn(v, selectedProperty[0], exports.min[0], exports.max[0]);
				});

			// y ticks
			exports.dcChart.yAxis()
				.ticks(10)
				.tickFormat(function (v) {
					return tickFormatFn(v, selectedProperty[1], exports.min[1], exports.max[1]);
				});

			function tickFormatFn(v, selectedProperty, min, max) {
				return (pluto.data.decimalKeys.indexOf(selectedProperty) === -1) ? Math.floor(v * (max - min) / exports.bin + min) : Math.ceil(v * 10 * (max - min) / exports.bin + min) / 10;
			}

			// render
			// exports.dcChart.render();
		}
	};

	pluto.ChartScatter.prototype = Object.create(pluto.Chart.prototype);

})();
