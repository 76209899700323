(function () {

	// Settings and class global vars
	var height = 200;

	var margins = {
		top: 10,
		right: 30,
		bottom: 45,
		left: 60
	};

	/**
	 * @constructor
	 * @extends pluto.Chart
	 */
	pluto.ChartHistogram = function (crossData) {

		pluto.Chart.apply(this, arguments);

		var exports = this;

		/**
		 * @override
		 */
		exports._abstract_create = function (parentDiv, selectedProperty, dimensions) {

			if (dimensions[0] && dimensions[0].clamps && dimensions[0].clamps.active) {
				createDimension(selectedProperty, dimensions[0].clamps[0], dimensions[0].clamps[1]);
			} else {
				createDimension(selectedProperty);
			}

			createChart(parentDiv, selectedProperty);

			return exports.dcChart;
		};

		/*
		 █████  ███    ██  ██████  ███    ██
		██   ██ ████   ██ ██    ██ ████   ██
		███████ ██ ██  ██ ██    ██ ██ ██  ██
		██   ██ ██  ██ ██ ██    ██ ██  ██ ██
		██   ██ ██   ████  ██████  ██   ████
		*/


		// creates the crossfilter dimension
		function createDimension(selectedProperty, clampedMin, clampedMax) {

			var isClamped = arguments.length !== 1;

			findMinMax();

			if (!isClamped) {
				findMinMax();
			} else {
				exports.min = clampedMin;
				exports.max = clampedMax;
			}

			// clears the basic dimension
			exports.disposeDimension();

			exports.cdim = crossData.dimension(function (d, i) {
				if (i === 0) {
					exports.legend.clear();
				}
				var val = d[selectedProperty];
				var result = exports.getBinValue(val, exports.min, exports.max);
				exports.legend.add(result, val, d);
				return result;
			});

			function findMinMax() {
				var top, bottom;

				var data = crossData._pluto_getAll();

				var extents = d3.extent(data, function (d) {
					return d[selectedProperty];
				});

				exports.min = extents[0];
				exports.max = extents[1];
			}

		}

		// creates the dc.js chart
		function createChart(parentDiv, selectedProperty) {
			// creates the group
			var grp = exports.cdim.group();

			exports.group = grp;

			// creates the chart
			exports.dcChart = dc.barChart(parentDiv[0]);

			// initialize the chart
			exports.dcChart.height(height)
				// margins: using a copy because it looks like dc.js actually changes
				// the margins object when rendering, and our left margin keeps inching over
				.margins({
					left: margins.left,
					right: margins.right,
					top: margins.top,
					bottom: margins.bottom
				})
				// axis scales
				.x(d3.scale.linear()
					.domain([0, exports.bin]))
				// grid lines
				.renderHorizontalGridLines(true)
				//set filter brush
				.brushOn(true)
				// y axis label
				// .xAxisLabel(selectedProperty)
				.yAxisLabel("#Lots")
				// elastic
				.elasticY(true)
				// dim and group
				.dimension(exports.cdim)
				.group(grp);

			// x ticks
			exports.dcChart.xAxis()
				.ticks(15)
				.tickFormat(function (v) {
					return exports.getNumericTickValue(v,exports.min,exports.max,selectedProperty);
				});

			exports.dcChart.yAxis()
				.ticks(5);

			// render
			// exports.dcChart.render();
		}
	};

	pluto.ChartHistogram.prototype = Object.create(pluto.Chart.prototype);

})();
