/**
 * @constructor
 * @param {object} config - Configuration object
 */
pluto.AnalysisType = function (config) {
	/**
	 * @lends pluto.PlutoSvc.AnalysisType.prototype
	 */
	var analysisType = this;

	/**
	 * Number of data sets involved in this analysis
	 * @type {Number}
	 */
	this.count = config.count || 1;

	/**
	 * Names of data sets chosen for analysis. When run, should have same num
	 * of strings as count.
	 * @type {array}
	 */
	this.namesChosen = config.namesChosen || [];

	/**
	 * Label for users to identify analysis
	 * @type {string}
	 */
	this.label = config.label || 'Analysis';
};
