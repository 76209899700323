/**
 * A set of visualization rules to follow
 * @constructor
 * @param {object} config - Configuration object
 */
pluto.VisualizationOption = function (config) {
	/**
	 * @lends pluto.VisualizationOption.prototype
	 */
	var visualizationOption = this;

	/**
	 * Selected color array: should be one of the arrays in the colorOptions array
	 * @type {string[]}
	 */
	visualizationOption.selectedColors = null;

	/**
	 * Datafilter that created this
	 * @type {pluto.Datafilter}
	 */
	visualizationOption.dataFilter = config.dataFilter || null;

	visualizationOption.specialColorDicts = [{
		key: 'LandUse',
		dict: {
			'01: One & Two Family': '#FFFF66',
			'02: Multi-Family Walk-Up': '#FFDD00',
			'03: Multi-Family Elevator': '#FFAA00',
			'04: Mixed Residential & Commercial': '#FF6600',
			'05: Commercial & Office': '#FF0000',
			'06: Industrial & Manufacturing': '#660066',
			'07: Transportation & Utility': '#555',
			'08: Public Facilities & Institutions': '#0033CC',
			'09: Open Space & Outdoor Recreation': '#33AA33',
			'10: Parking Facilities': '#333',
			'11: Vacant Land': '#00FFCC'
		}
	}];

	visualizationOption.curSpecialColorDict = null;
};

pluto.VisualizationOption.colorArrays = {
	/**
	 * Ordinal color array options (array of array of hex strings)
	 * @type {array[]}
	 */
	ordinal: [
			// d3.scale.category10().range(),
			d3.scale.category20()
			.range(),
			d3.scale.category20b()
			.range(),
			d3.scale.category20c()
			.range()
		],
	/**
	 * Linear color array options (array of array of hex strings), will be interpolated evenly along gradient
	 * @type {array[]}
	 */
	linear: [
			['#DAF7A6', '#FFC300', '#FF5733', '#C70039', '#900C3F', '#581845'],
			['#DDD', '#111']
		]
};

pluto.VisualizationOption.prototype.ensureColorInit = function () {
	var visualizationOption = this;
	var dim = visualizationOption.dataFilter.dimensions[0];
	// clear prev special dict
	if (visualizationOption.curSpecialColorDict) visualizationOption.selectedColors =null;
	// Check if special dict
	visualizationOption.curSpecialColorDict = visualizationOption.specialColorDicts.filter(function (dict) {
		return dict.key === dim.key;
	})[0];
	if (visualizationOption.curSpecialColorDict) {
		visualizationOption.selectedColors = Object.keys(visualizationOption.curSpecialColorDict.dict)
			.map(function (key) {
				return visualizationOption.curSpecialColorDict.dict[key];
			});
	} else if (!visualizationOption.selectedColors && dim) {
		visualizationOption.selectedColors = pluto.VisualizationOption.colorArrays[dim.type][0];
	}
};
