/**
# Urbane 2015.
# Pluto data viewer
*/

pluto.ColorScale = function (analysisConfig, min, max) {

	// exported object
	var exports = {};

	var colorScale;

	var nullColor = '#000';

	if (analysisConfig.selectedColors.key) createDict();
	else createNormal();

	// linear interpolation
	function lerp(a, b, u) {
		return (1 - u) * a + u * b;
	}

	function createDict() {
		colorScale = function (result) {
			return analysisConfig.selectedColors.dict[result];
		};
	}

	function createNormal() {
		var conversionScale = d3.scale.linear()
			.range([min, max])
			.domain([0, analysisConfig.selectedColors.length - 1]);

		if (analysisConfig.type === 'linear') {
			colorScale = d3.scale.linear();
			colorScale.domain(analysisConfig.selectedColors.map(function (color, i) {
				return conversionScale(i);
			}));
		}
		if (analysisConfig.type === 'ordinal') {
			colorScale = d3.scale.ordinal();
		}
		colorScale.range(analysisConfig.selectedColors);
	}

	// protected color computation
	exports.getColor = function (val, isDiv) {
		if (val === '') return nullColor;
		// console.log(val,colorScale(val));
		return colorScale(val);
	};

	// Threejs color
	exports.getThreeColor = function (val, isDiv) {
		// computes the values
		var colorVal = exports.getColor(val, isDiv);
		// Three.js color
		var color = new THREE.Color(colorVal);
		// return the color
		return color;
	};

	// public api
	return exports;
};
