/**
# Urbane 2015.
# Pluto data viewer
*/

pluto.Data = function () {
	// exported object
	var exports = {};

	// load the available datasets from a json file
	exports.availableNamesFile = function (settingsFileName) {
		return new Promise(function (resolve, reject) {

			var settingsLocation=pluto.urlPrefix + "sets/" + settingsFileName + ".js";

			console.log('Reading',settingsLocation);

			$.getScript(pluto.urlPrefix + "sets/" + settingsFileName + ".js", function () {
				console.log('Settings read',SETTINGS);
				// store the json files available
				pluto.availablePlutoNames = SETTINGS.jsonDataSets;
				pluto.data.processSettingsFile(SETTINGS)
					.then(function () {
						return resolve();
					});
			},function(){
				console.log("error?",arguments);
			});

		});
	};

	// load a pluto json
	exports.loadDataSetFile = function (dataName) {
		console.time("downloading " + dataName);

		return new Promise(function (resolve, reject) {

			$.getJSON(pluto.urlPrefix + "json/" + dataName + ".json", function (json) {

				// parses json
				var jsonData = JSON.parse(json);

				// TODO: Clean data instead of doing this here.
				// Remote this data error...
				jsonData.forEach(pluto.data.processLot);

				// stores the crossfilter
				pluto.loadedDataSet[dataName] = crossfilter(jsonData);
				pluto.loadedDataSet[dataName]._pluto_getAll = function () {
					return jsonData;
				};

				// Create a dimension that we always keep but never filter: so that we
				// can always use it to get all currently filtered data
				pluto.loadedDataSet[dataName].persistentDim = pluto.loadedDataSet[dataName].dimension(function () {
					return 1;
				});

				console.timeEnd("downloading " + dataName);
				return resolve(jsonData);
			});

		});

	};

	return exports;
};
