/*
# Urbane 2015.
# Pluto data viewer
*/

pluto.Layer = function () {
	// Group of Three.js elements
	var stroke;
	// Group of Three.js elements
	var fill;

	// material definition
	var fillMtl = new THREE.MeshBasicMaterial({
		shading: THREE.FlatShading,
		side: THREE.DoubleSide,
		vertexColors: THREE.FaceColors,
		polygonOffset: true
	});

	// exported api
	var exports = {};

	// Three.js stroke layer creation
	exports.loadStrokeLayer = function (glCanvas, crossData, render) {
		// default value
		render = typeof render !== 'undefined' ? render : true;

		// console message
		console.time("Loading pluto stroke layer");

		// gets the polygon dimension
		var dim = crossData.dimension(function (d) {
			return d.MY_BBL;
		});
		// get all polygons
		var data = dim.top(Infinity);

		// creates the strokes
		stroke = new THREE.Geometry();
		// auxiliary geometry
		var gmt = new THREE.Geometry();

		// material definition
		var mtl = new THREE.LineBasicMaterial({
			color: new THREE.Color("rgb(255,255,255)")
		});

		mtl.transparent = true;
		mtl.opacity = 0.2;


		// loops over the polygons
		var poly = [];
		for (var pid = 0, plen = data.length; pid < plen; ++pid) {
			// gets the poly
			poly = data[pid].MY_POLYGON;

			// first point
			var vtx0 = new google.maps.LatLng(poly[0].y, poly[0].x);
			// converts lng,lat to google lat,long
			vtx0 = glCanvas.fromLatLngToVertex(vtx0);
			vtx0.z = 10;

			// loops over the vertices
			for (var vid = 0, vlen = poly.length; vid < vlen - 1; vid++) {
				// new point
				var vtx1 = new google.maps.LatLng(poly[vid + 1].y, poly[vid + 1].x);
				// converts lat,lng to x,y
				vtx1 = glCanvas.fromLatLngToVertex(vtx1);
				vtx1.z = 10;

				// stores the point
				gmt.vertices[2 * vid] = vtx0;
				gmt.vertices[2 * vid + 1] = vtx1;

				// copies the current to old
				vtx0 = vtx1;
			}

			// merges the geometry
			stroke.merge(gmt);

			// frees the geometry
			gmt.vertices = [];
		}

		stroke = new THREE.LineSegments(stroke, mtl);

		// adds the group to canvas
		if (render) glCanvas.add(stroke);

		// frees the dimension
		dim.filterAll();
		dim.dispose();

		// logs the
		console.timeEnd("Loading pluto stroke layer");
	};

	// Three.js fill Layer creation
	exports.loadFillLayer = function (glCanvas, analysisConfig, data) {

		var funcName = analysisConfig.selectedKey;

		// console message
		// console.log("Loading pluto fill layer...");
		console.time("Loading pluto fill layer:");

		// Destroy old fill object
		if (fill && fill.dispose) fill.dispose();

		// creates the group
		fill = new THREE.Geometry();
		// three.js geometry
		var gmt = new THREE.Geometry();

		var fidsToPids = [];

		var mtl = fillMtl;

		// loops over the json
		var nlots = 0,
			poly = [];
		for (var pid = 0, plen = data.length; pid < plen; ++pid) {
			// gets the poly
			poly = data[pid].MY_POLYGON;

			// avoid empty polygons
			if (!poly.length) continue;

			var val = data[pid][funcName];

			// loops over the vertices
			for (var vid = 0, vlen = poly.length; vid < vlen; vid++) {
				// converts lng,lat to google lat,long
				var vtx = new google.maps.LatLng(poly[vid].y, poly[vid].x);
				// converts lat,lng to x,y
				vtx = glCanvas.fromLatLngToVertex(vtx);
				// stores the point
				gmt.vertices[vid] = vtx;
			}

			// face color
			var fColor = data[pid].__threeColor; //colorMap.getThreeColor(val);

			// triangulates the region
			var triangles = THREE.Shape.Utils.triangulateShape(gmt.vertices, []);
			// loads the triangles
			for (var fid = 0, tlen = triangles.length; fid < tlen; fid++) {
				// trig vertices
				var trig = new THREE.Face3(triangles[fid][0], triangles[fid][1], triangles[fid][2]);
				// trig colors
				trig.color = fColor;
				// appends the face
				gmt.faces[fid] = trig;
			}

			// adds one lot
			nlots += 1;

			// creates the mesh
			var mesh = new THREE.Mesh(gmt, mtl);
			// update matrix
			mesh.updateMatrix();
			// adds the mesh to the group
			fill.merge(mesh.geometry, mesh.matrix);

			while (fidsToPids.length < fill.faces.length) {
				fidsToPids.push(pid);
			}

			// frees the geometry
			gmt.vertices = [];
			gmt.faces = [];
		}

		fill = new THREE.Mesh(fill, mtl);

		fill.userData.fidsToPids = fidsToPids;
		fill.userData.originalData = data;

		// adds to the canvas
		glCanvas.add(fill);

		// logs the time this took
		console.timeEnd("Loading pluto fill layer:");
	};

	// Access to the group
	exports.getGeometry = function () {
		// returns the stroke object
		return stroke;
	};

	// public api
	return exports;
};
