pluto.Legend = function () {
	var legend = this;
};

pluto.Legend.prototype.clear = function () {
	var legend = this;

	Object.keys(legend)
		.forEach(function (key) {
			legend[key] = undefined;
		});
};

pluto.Legend.prototype.add = function (result, value, original) {
	var legend = this;

	var membersContainer = legend.getMembersContainer(result);

	membersContainer.members.push({
		value: value,
		data: original
	});

};

pluto.Legend.prototype.getMembersContainer = function (result) {
	var legend = this;

	var strResult = String(result);

	if (!legend[strResult]) {
		legend[strResult] = {
			result: result,
			members: []
		};
	}

	return legend[strResult];
};
