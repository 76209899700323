(function () {

	/**
	 * A chart: all chart behavior will inherit from this (histogram, scatter)
	 * @constructor
	 */
	pluto.Chart = function (cData) {

		var chart = this;

		/**
		 * Crossfilter dimension
		 * @type {object}
		 */
		chart.cdim = undefined;

		/**
		 * DC.js chart
		 * @type {dc.rowChart|dc.barChart|dc.scatterPlot}
		 */
		chart.dcChart = undefined;

		/**
		 * Number of bins for numerical values
		 * @type {Number}
		 */
		chart.bin = 30; // Used for some, not all charts.

		/**
		 * Legend
		 * @type {pluto.Legend}
		 */
		chart.legend = undefined; // Will be set later

		/**
		 * Parent div
		 * @type {jQuery}
		 */
		chart.parentDiv = undefined;

		/**
		 * Minimum val
		 * @type {number}
		 */
		chart.min = null;

		/**
		 * Maximum val
		 * @type {number}
		 */
		chart.max = null;
	};

	/**
	 * Destroy chart dimension
	 */
	pluto.Chart.prototype.disposeDimension = function () {
		var chart = this;
		if (chart.cdim) {
			chart.cdim.filterAll();
			chart.cdim.dispose();
		}
	};

	/**
	 * Create a dc.js chart
	 * @param  {jQuery} parentDiv        - Parent div to create in
	 * @param  {string} selectedProperty - Selected property to visualize by
	 * @param {dataFilterDimension[]} - Data dimensions
	 * @return {dc.someChartType}        - Whatever chart type the extending class creates
	 */
	pluto.Chart.prototype.create = function (parentDiv, selectedProperty, dimensions) {
		var chart = this;

		chart.parentDiv = parentDiv;

		// use dynamic width
		var width = parentDiv.innerWidth();

		$(window)
			.on('resize', function () {
				width = parentDiv.innerWidth();
				if (chart.dcChart && chart.dcChart.render) {
					chart.dcChart.width(width);
					chart.dcChart.render();
				}
			});

		// Legend bins
		chart.legend = new pluto.Legend();

		// Clears previous dimension before abstract function creates a new one
		chart.disposeDimension();

		return chart._abstract_create(parentDiv, selectedProperty, dimensions);

	};

	/**
	 * Gets a bin value
	 * @param  {number} value - Number of the value to bin
	 * @param  {number} min   - Min value in bin range
	 * @param  {number} max   - Max value in bin range
	 * @return {number}       - Binned value
	 */
	pluto.Chart.prototype.getBinValue = function (value, min, max) {
		var chart = this;
		var returnVal = Math.floor((+value - min) / (max - min) * chart.bin);
		if (value > max) returnVal = -1;
		if (value < min) returnVal = -1;
		if (returnVal < 0) returnVal = -1;
		if (isNaN(value)) returnVal = -1;
		return returnVal;
	};

	var numFormat=d3.format(',');

	pluto.Chart.prototype.getNumericTickValue = function (binValue, min, max, selectedProperty) {
		var chart=this;
		var returnNum= (pluto.data.decimalKeys.indexOf(selectedProperty) === -1) ? Math.ceil(binValue * (max - min) / chart.bin + min) : Math.ceil(binValue * 10 * (max - min) / chart.bin + min) / 10;
		return numFormat(returnNum);
	};



	/*
	 █████  ██████  ███████ ████████ ██████   █████   ██████ ████████
	██   ██ ██   ██ ██         ██    ██   ██ ██   ██ ██         ██
	███████ ██████  ███████    ██    ██████  ███████ ██         ██
	██   ██ ██   ██      ██    ██    ██   ██ ██   ██ ██         ██
	██   ██ ██████  ███████    ██    ██   ██ ██   ██  ██████    ██
	*/

	/**
	 * Abstract class intended for override: called after base class create functionality
	 * @abstract
	 */
	pluto.Chart.prototype._abstract_create = function () {};


})();
