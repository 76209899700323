pluto.data = {};

pluto.data.settings = {
	'raw': null, // fill in later
	'allDefaultKeys': { // fill in later
		'keep': null,
		'ignore': null
	}
};

pluto.data.toMerge = {};

(function loadExtraData() {

	/*
	██ ███    ██ ██ ████████
	██ ████   ██ ██    ██
	██ ██ ██  ██ ██    ██
	██ ██  ██ ██ ██    ██
	██ ██   ████ ██    ██
	*/

	d3.csv("data/salesdata.csv", function (data) {
		pluto.data.sales = {};

		data.forEach(function (datum) {
			pluto.data.sales[datum.bbl_pluto] = pluto.data.sales[datum.bbl_pluto] || [];
			pluto.data.sales[datum.bbl_pluto].push(datum);
		});
	});

	d3.csv("data/inflation.csv", function (data) {
		pluto.data.inflation = {};

		data.forEach(function (datum) {
			pluto.data.inflation[datum.year] = datum.val_in_2017;
		});
	});


	/*
	███████ ███    ██
	██      ████   ██
	█████   ██ ██  ██
	██      ██  ██ ██
	██      ██   ████
	*/

	pluto.data.processSettingsFile = function (jsonSettings) {
		return new Promise(function (resolve, reject) {

			pluto.data.settings.raw = jsonSettings;

			getAdditionalCsvInfo().then(function(){
				processDimensions();
				resolve();
			});

		});

		function getAdditionalCsvInfo() {

			return new Promise(function (resolve, reject) {

				var countProcessed=0;

				if (pluto.data.settings.raw.additionalData) {
					pluto.data.settings.raw.additionalData.csvsToMerge.forEach(function (csvLoc, i) {

						d3.csv(csvLoc, function (data) {
							data.forEach(function (datum) {
								pluto.data.toMerge[datum.BBL] = pluto.data.toMerge[datum.BBL] || {};
								Object.keys(datum)
									.forEach(function (key) {
										pluto.data.toMerge[datum.BBL][key] = datum[key];
									});
							});

							countProcessed+=1;
							if(countProcessed===pluto.data.settings.raw.additionalData.csvsToMerge.length) resolve();

						});
					});
				} else {
					resolve();
				}
			});

		}

		function processDimensions() {
			pluto.data.settings.allDefaultKeys.keep = pluto.data.settings.raw.jsonFileData.functionLinear.keep.concat(pluto.data.settings.raw.jsonFileData.functionOrdinal.keep);
			pluto.data.settings.allDefaultKeys.ignore = pluto.data.settings.raw.jsonFileData.functionLinear.ignore.concat(pluto.data.settings.raw.jsonFileData.functionOrdinal.ignore);

			var extraLinearKeys = [];
			var extraOrdinalKeys = [];

			if (pluto.data.settings.raw.additionalData && pluto.data.settings.raw.additionalData.keysToAdd) {
				if (pluto.data.settings.raw.additionalData.keysToAdd.linear) extraLinearKeys = pluto.data.settings.raw.additionalData.keysToAdd.linear;
				if (pluto.data.settings.raw.additionalData.keysToAdd.ordinal) extraOrdinalKeys = pluto.data.settings.raw.additionalData.keysToAdd.ordinal;
			}

			pluto.availableFunctionNames[0] = pluto.data.settings.raw.jsonFileData.functionLinear.keep.concat(extraLinearKeys);
			pluto.availableFunctionNames[1] = pluto.data.settings.raw.jsonFileData.functionOrdinal.keep.concat(extraOrdinalKeys);

			pluto.availableFunctionNames.linear = pluto.availableFunctionNames[0];
			pluto.availableFunctionNames.ordinal = pluto.availableFunctionNames[1];

			pluto.data.decimalKeys = pluto.data.settings.raw.decimalKeys;
		}

	};

	var landUseDict = {
		'01': '01: One & Two Family',
		'02': '02: Multi-Family Walk-Up',
		'03': '03: Multi-Family Elevator',
		'04': '04: Mixed Residential & Commercial',
		'05': '05: Commercial & Office',
		'06': '06: Industrial & Manufacturing',
		'07': '07: Transportation & Utility',
		'08': '08: Public Facilities & Institutions',
		'09': '09: Open Space & Outdoor Recreation',
		'10': '10: Parking Facilities',
		'11': '11: Vacant Land'
	};

	/**
	 * Additional processing of each lot
	 * @param  {object} lot - Object representing one lot or piece of geometry
	 */
	pluto.data.processLot = function (lot, i) {
		var curYear = (new Date())
			.getFullYear();

		if (lot.yearbuilt > curYear) lot.yearbuilt = curYear;

		// Convert to titlecase
		pluto.data.settings.allDefaultKeys.keep.forEach(function (key) {
			var lowerKey = key.toLowerCase();
			lot[key] = lot[lowerKey];
			delete lot[lowerKey];
		});

		// delete keys we're ignoring to free up memory
		pluto.data.settings.allDefaultKeys.ignore.forEach(function (key) {
			delete lot[key];
		});

		lot.LandUse = landUseDict[lot.LandUse] || '';

		if (!pluto.data.toMerge[lot.MY_BBL]) {
			// Set default value of -1 if not found
			if (pluto.data.settings.raw.additionalData && pluto.data.settings.raw.additionalData.keysToAdd && pluto.data.settings.raw.additionalData.keysToAdd.linear) {
				pluto.data.settings.raw.additionalData.keysToAdd.linear.forEach(function (linearKey) {
					lot[linearKey] = -1;
				});
			}
			return;
		} else {

			Object.keys(pluto.data.toMerge[lot.MY_BBL])
				.forEach(function (key) {
					var value = pluto.data.toMerge[lot.MY_BBL][key];
					if (pluto.availableFunctionNames[0].indexOf(key) !== -1) {
						lot[key] = Number(value);
					} else {
						lot[key] = value;
					}
				});

			// TO DO:::
			// lot.SubwayAndBQXDist = convertDist(Number(pluto.data.toMerge[lot.MY_BBL].Sbwy_DIST));
			// lot.SubwayDist = convertDist(lot.SubwayDist);
		}


	};

	function convertDist(num) {
		var numMiles = num / 5280;
		var rounded = Math.round(numMiles * 100);
		return rounded / 100;
	}

})();
