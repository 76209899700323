/*
# Urbane 2015.
# Pluto data viewer
*/

pluto.Map = function () {
	// map object
	var map;
	var thr;

	// exported api
	var exports = {};

	var mapDomId;
	var mapLoadDiv = $('<div id="gmaps-loading"></div>');
	var meshes;

	var raycaster = new THREE.Raycaster();
	var mouse = new THREE.Vector2();

	var mapDataDiv = $('<div id="map-data-tooltip"></div>');

	// create a new pluto layer
	function createNewPluto(dataName, render) {

		// crossfilter set
		var dataSet = pluto.loadedDataSet[dataName];

		// creates the layers
		var layer = new pluto.Layer();
		// create layer.
		layer.loadStrokeLayer(thr, dataSet, render);

		// store loaded layer
		pluto.loadedPluto[dataName] = layer;
	}

	// create a new pluto layer
	function createNewFunctionView(name, analysisConfig, data) {
		// json set
		var dataSet = pluto.loadedDataSet[name];
		// gets the pluto
		var layer = pluto.loadedPluto[name];
		// create layer.
		layer.loadFillLayer(thr, analysisConfig, data);

		meshes = thr.scene.children.filter(function (child) {
			return child.type === 'Mesh';
		});
	}

	// Three.js layer cleanup
	exports.initGL = function (glCanvas) {
		// resets the scene
		glCanvas.scene = new THREE.Scene();
	};

	//
	exports.initPlutoNoRender = function () {
		// previously loaded layer
		if (pluto.selectedPlutoCompareName in pluto.loadedPluto) return;

		// creates a new layer
		createNewPluto(false);
	};

	// Three.js pluto render creatioz
	exports.initPluto = function (name) {
		// clears the scene
		thr.clearScene();

		// previously loaded layer
		if (name in pluto.loadedPluto) {
			// loads the corresponding pluto
			var geom = pluto.loadedPluto[name].getGeometry();
			thr.loadObject(geom);
		} else {
			// creates a new layer
			createNewPluto(name, true);
		}
		// redraw
		thr.draw();
	};

	// Three.js function render creation
	exports.initFunctionView = function (name, analysisConfig, data) {
		// only load functions if selected is available
		if (!(name in pluto.loadedPluto))
			return;

		// clears the scene
		thr.clearScene();

		// creates the function
		createNewFunctionView(name, analysisConfig, data);

		// loads the corresponding pluto
		var geom = pluto.loadedPluto[name].getGeometry();
		thr.loadObject(geom);

		// redraw
		thr.draw();
	};

	// map creation
	exports.createMap = function (domId) {
		mapDomId = domId;

		// get the container
		var container = document.getElementById(domId);
		domContainer = $('#' + domId);

		// creates the map
		map = new google.maps.Map(container, {
			// zoom: 16,
			zoom: 14,
			mapTypeControl: false,
			streetViewControl: false,
			// center: new google.maps.LatLng(40.756119, -73.983159),
			center: new google.maps.LatLng(40.7356519574258, -73.97557371290587),
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles: pluto.styles
		});

		// creates the three.js layer
		thr = new ThreejsLayer({
			map: map
		}, exports.initGL);

		$('body')
			.append(mapDataDiv);
		mapDataDiv.hide();

		var lastX;
		var lastY;
		var previousHovers = [];

		google.maps.event.addListener(map, 'zoom_changed', function () {
			mapDataDiv.hide();
		});

		domContainer.mousedown(function (e) {
			mapDataDiv.hide();
			lastX = e.clientX;
			lastY = e.clientY;
		});

		domContainer.mouseup(function (e) {
			if (e.clientX !== lastX || e.clientY !== lastY) return; // guard against mouse drag events
			if (!meshes) return; // we don't have any meshes to calculate against
			// calculate mouse position in normalized device coordinates
			// (-1 to +1) for both components
			mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
			mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

			mapDataDiv.css('left', e.clientX);
			mapDataDiv.css('top', e.clientY);

			raycaster.setFromCamera(mouse, thr.camera);

			// calculate objects intersecting the picking ray
			var intersects = raycaster.intersectObjects(meshes, true);

			var curHover = intersects.reduce(function (prev, cur) {
				// debugger;
				var pid = cur.object.userData.fidsToPids[cur.faceIndex];
				var originalData = cur.object.userData.originalData[pid];

				return originalData ? {
					intersect: cur,
					data: originalData
				} : prev;
			}, null);

			if (curHover) {
				if (previousHovers[previousHovers.length - 1] && previousHovers[previousHovers.length - 1].data === curHover.data) {
					// we're still clicking on the last thing
					return;
				} else {
					// we found something new
					unselectPrevious();
					populateTooltip();
				}
			} else {
				// No intersections found
				mapDataDiv.html('');
				unselectPrevious();
			}

			function populateTooltip() {

				var threeColor = curHover.data.__threeColor;

				var hexColor = '#' + threeColor.getHexString();

				mapDataDiv.html('');
				var table = $('<table class="table table-condensed"></table>');
				var doNotDisplayKeys = ['MY_BBL'];
				Object.keys(curHover.data)
					.sort()
					.forEach(function (key) {
						if (doNotDisplayKeys.indexOf(key) !== -1) return;
						var tr = $('<tr></tr>');
						if (typeof curHover.data[key] === 'object') return;
						tr.append('<td>' + key + '</td>');
						tr.append('<td><strong>' + curHover.data[key] + '</strong></td>');
						tr.children('td')
							.css('border-color', 'rgba(' + [threeColor.r * 255, threeColor.g * 255, threeColor.b * 255].join(',') + ',0.5)');
						table.append(tr);
					});

				// Sales
				var salesTr = $('<tr></tr>');
				salesTr.append('<td>Sales Records</td>');
				var salesTd = $('<td></td>');

				var sales = pluto.data.sales[curHover.data.MY_BBL];

				if (!sales) salesTd.html('Unavailable');
				else drawSalesTable(sales);

				salesTr.append(salesTd);
				table.append(salesTr);

				mapDataDiv.append(table);
				mapDataDiv.css('border', '5px solid ' + hexColor);
				mapDataDiv.show();

				function drawSalesTable(sales) {
					var saleTable = $('<table></table>');
					saleTable.append('<tr><th>Year</th><th>$/sf</th><th>$/sf today</th></tr>');
					sales.forEach(function (sale) {
						saleTable.append('<tr><td style="padding-right: 5px">' + sale.sale_year + '</td><td style="padding-right: 5px">$' + Math.round(sale.price_per_sqft * 100) / 100 + '</td><td style="padding-right: 5px">$' + Math.round(sale.price_per_sqft * 100 * pluto.data.inflation[sale.sale_year]) / 100 + '</td></tr>');
					});
					salesTd.append(saleTable);
				}
			}

			function unselectPrevious() {
				// un-hover everything we did before
				// previousHovers.forEach(function (hover) {
				// 	hover.intersect.face.color = hover.data.__threeColor;
				// });
				previousHovers.splice(0, previousHovers.length);
			}

		});
	};

	// return the public api
	return exports;
};
