/**
 * One filter for data
 * @constructor
 * @param {object} config - Configuration object
 */
pluto.DataFilter = function (config) {
	/**
	 * @lends pluto.DataFilter.prototype
	 */
	var dataFilter = this;

	/**
	 * Dimension we're looking at
	 * @type {dataFilterDimension}
	 */
	dataFilter.dimensions = [];

	/**
	 * We'll fill this in when we draw charts
	 * @type {pluto.Histogram|pluto.Scatter}
	 */
	dataFilter.chart = null;

	/**
	 * We'll fill this with some kind of dc.js chart later
	 * @type {Object}
	 */
	dataFilter.dcChart = null;

	/**
	 * Stored last chart config we were passed
	 * @type {object}
	 */
	dataFilter.chartConfig = null;

	/**
	 * Data count
	 * @type {Number}
	 */
	dataFilter.dataCount = 0;

};

/**
 * Set the current dimension of this data filter
 * @param {number} i - Index of dimension to set
 * @param  {dataFilterDimension} dimension - Dimension we're going to filter by
 */
pluto.DataFilter.prototype.chooseDimension = function (i, dimension) {
	var dataFilter = this;
	dataFilter.dimensions[i] = dimension;
};

/**
 * Get all data objects in this dimension
 * @return {object[]} - Data objects
 */
pluto.DataFilter.prototype.getData = function () {
	var dataFilter = this;
	var data = dataFilter.chartConfig.crossData.persistentDim.top(Infinity);
	dataFilter.dataCount = data.length;
	return data;
};

/**
 * Get all filtered data (after clamping)
 * @return {object[]} [description]
 */
pluto.DataFilter.prototype.getFilteredData = function () {
	var dataFilter = this;
	var data = dataFilter.getData();
	Object.keys(dataFilter.chart.legend)
		.forEach(function (key) {
			if (key !== '-1') return;
			dataFilter.chart.legend[key].members.forEach(function (m) {
				m.data.__threeColor = new THREE.Color('#000');
				// data.splice(data.indexOf(m.data), 1); // remove from stuff to render
			});
		});
	return data;
};


/**
 * Get extent given a key
 * @param  {string} key - Key to get by
 * @return {number[]}     min max array
 */
pluto.DataFilter.prototype.getExtent = function (key) {
	var dataFilter = this;
	var data = dataFilter.chartConfig.crossData._pluto_getAll();
	return d3.extent(data, function (d) {
		return d[key];
	});
};

/**
 * Get extent of whatever we colored by
 */
pluto.DataFilter.prototype.getColorExtent = function () {
	var dataFilter = this;
	var data = dataFilter.getData();
	return dataFilter.getExtent(dataFilter.getColorKey());
};

/**
 * Get key we're coloring by
 */
pluto.DataFilter.prototype.getColorKey = function () {
	var dataFilter = this;
	return dataFilter.dimensions[0].key;
};

/**
 * Get type we're coloring by
 */
pluto.DataFilter.prototype.getColorType = function () {
	var dataFilter = this;
	return dataFilter.dimensions[0].type;
};

/**
 * Draw chart in this div
 * @param  {jQuery} targetDiv - DOM element to populate
 * @param {object} config - Config object. Contains crossData,map,jsonDataName,curVisualization
 */
pluto.DataFilter.prototype.createChart = function (targetDiv, config) {

	var dataFilter = this;

	dataFilter.chartConfig = config;

	// Clear div
	targetDiv.html('');

	var isScatter = dataFilter.dimensions[0] && dataFilter.dimensions[1];

	if (dataFilter.chart) dataFilter.chart = undefined;
	if (dataFilter.dcChart) dc.chartRegistry.deregister(dataFilter.dcChart, dataFilter.dcChart.chartGroup());

	if (isScatter) {
		// scatter
		dataFilter.chart = new pluto.ChartScatter(dataFilter.chartConfig.crossData);
		dataFilter.dcChart = dataFilter.chart.create(targetDiv, dataFilter.dimensions.map(function (dim) {
			return dim.key;
		}), dataFilter.dimensions);
	} else if (dataFilter.dimensions[0]) {
		// not scatter
		if (dataFilter.dimensions[0].type === 'linear') dataFilter.chart = new pluto.ChartHistogram(dataFilter.chartConfig.crossData);
		if (dataFilter.dimensions[0].type === 'ordinal') dataFilter.chart = new pluto.ChartRow(dataFilter.chartConfig.crossData);

		dataFilter.dcChart = dataFilter.chart.create(targetDiv, dataFilter.dimensions[0].key, dataFilter.dimensions);
	}

	dataFilter.drawChart();
};

pluto.DataFilter.prototype.drawChart = function () {

	var dataFilter = this;

	var isScatter = (dataFilter.chart instanceof pluto.ChartScatter);

	if (dataFilter.chartConfig.curVisualization && dataFilter.chartConfig.curVisualization.dataFilter === dataFilter) calculateColors();
	else calculateNoColors();

	// console.log(dataFilter.chart.group.all());

	dataFilter.dcChart.render();

	// Calculate when we are not coloring by this filter
	function calculateNoColors() {
		dataFilter.dcChart.colors(function () {
			return '#000';
		});
	}

	// Calculate colors
	function calculateColors() {

		var funcName = dataFilter.getColorKey();
		var type = dataFilter.getColorType();
		var extent = dataFilter.getColorExtent();
		var min = extent[0];
		var max = extent[1];

		var colorScale;

		if (type === 'ordinal') {
			colorScale = new pluto.ColorScale({
				type: type,
				selectedColors: dataFilter.chartConfig.curVisualization.curSpecialColorDict || dataFilter.chartConfig.curVisualization.selectedColors
			}, min, max);
		}
		if (type === 'linear') {
			colorScale = new pluto.ColorScale({
				type: type,
				selectedColors: dataFilter.chartConfig.curVisualization.selectedColors
			}, 0, dataFilter.chart.bin);
		}

		dataFilter.dcChart.colors(colorFn);
		dataFilter.dcChart.colorAccessor(colorAccessorFn);

		function colorAccessorFn(d) {
			return d;
		}

		function colorFn(d, i) {
			var value = isScatter ? d.key[0] : d.key;
			var color = colorScale.getColor(value);
			var members = dataFilter.chart.legend.getMembersContainer(value)
				.members;
			var threeColor = new THREE.Color(color);
			members.forEach(function (m) {
				m.data.__threeColor = threeColor;
			});
			return color;
		}
	}
};

/**
 * Draw the map (must have drawn the chart first)
 */
pluto.DataFilter.prototype.drawMap = function () {
	var dataFilter = this;

	return new Promise(function (resolve, reject) {
		var config = dataFilter.chartConfig;
		if (!config || !config.map || !config.jsonDataName || !config.curVisualization) return;
		config.map.initFunctionView(config.jsonDataName, config.curVisualization, dataFilter.getFilteredData());
		resolve();
	});

};

/**
 * @typedef dataFilterDimension
 * @property {string} type - 'linear' or 'ordinal'
 * @property {string} key - Key of dimension
 * @property {number[]|array[]} [clamps] - Clamped range
 */

/**
 * Available dimensins to filter by
 * @type {availableDimension[]}
 */
pluto.DataFilter.availableDimensions = [];
