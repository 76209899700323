(function () {

	/**
	 * @constructor
	 * @extends pluto.Chart
	 * @param {object} crossData - Crossfilter data
	 */
	pluto.ChartRow = function (crossData) {

		pluto.Chart.apply(this, arguments);

		/**
		 * @lends pluto.ChartRow.prototype
		 */
		var exports = this;

		/**
		 * @override
		 */
		exports._abstract_create = function (parentDiv, selectedProperty, dimensions) {
			// No clamp behavior for ordinal: not using dimensions argument for now
			createDimension(selectedProperty);
			createChart(parentDiv, selectedProperty);
			return exports.dcChart;
		};

		/*
		 █████  ███    ██  ██████  ███    ██
		██   ██ ████   ██ ██    ██ ████   ██
		███████ ██ ██  ██ ██    ██ ██ ██  ██
		██   ██ ██  ██ ██ ██    ██ ██  ██ ██
		██   ██ ██   ████  ██████  ██   ████
		*/

		// creates the crossfilter dimension
		function createDimension(selectedProperty) {
			exports.cdim = crossData.dimension(function (d, i) {
				if (i === 0) exports.legend.clear();
				var val = d[selectedProperty];
				var result = (val.indexOf("b'") >= 0) ? "None" : val;
				exports.legend.add(result, val, d);
				return result;
			});
		}

		// creates the dc.js chart
		function createChart(parentDiv, selectedProperty) {
			// creates the group
			var grp = exports.cdim.group();

			exports.group = grp;

			// creates the chart
			exports.dcChart = dc.rowChart(parentDiv[0]);

			var margins = {
				top: 10,
				right: 30,
				bottom: 45,
				left: 10
			};

			// initialize the chart
			exports.dcChart
				.height(
					grp.all()
					.length * 20 + margins.bottom + margins.top
				)
				// margins: using a copy because it looks like dc.js actually changes
				// the margins object when rendering, and our left margin keeps inching over
				.margins({
					left: margins.left,
					right: margins.right,
					top: margins.top,
					bottom: margins.bottom
				})
				// // axis scales
				.x(d3.scale.ordinal())
				.labelOffsetX(4)
				// .ordinalColors(['#FFCC00'])
				// .xUnits(dc.units.ordinal)
				// // grid lines
				// .renderHorizontalGridLines(true)
				// // y axis label
				// .xAxisLabel(selectedProperty)
				// .yAxisLabel("#Lots")
				// // elastic
				.elasticX(true)
				// // dim and group
				.dimension(exports.cdim)
				.group(grp);

			// x ticks
			exports.dcChart.xAxis()
				.ticks(5);

			// render
			// exports.dcChart.render();
		}

	};

	pluto.ChartRow.prototype = Object.create(pluto.Chart.prototype);

})();
